.testimonials {
  font-family: var(--primaryFont);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* SLIDER STYLES */
.slick-slider {
  height: 100% !important;
  width: 100%;
}

.slick-initialized {
  overflow: hidden;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}
.slick-dots {
  position: relative !important;
  bottom: 1rem !important;
}

.testimonials--header h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 3.5rem;
  text-align: center;
}

.testimonials--body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 95%;
  margin-top: 1rem;

  position: relative;
}

.testimonials--body .quote {
  font-size: 4rem;
  position: absolute;
  z-index: 10;

  transform: translateY(50%);

  opacity: 0.5;
}

.testimonials--body button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  appearance: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.6rem;
}
.testimonials--body .prevBtn {
  left: 5%;
  cursor: pointer;
}
.testimonials--body .nextBtn {
  right: 5%;
  cursor: pointer;
}

.testimonials--slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
}

.single--testimony {
  padding: 2rem;
  width: 100%;
  height: 500px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testimonials--container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review--img {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 3;
  transform: translateY(50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
}
.review--img img {
  width: 90px;
}

.review--content {
  margin: 0 auto;
  width: 90%;
  height: 250px;
  border-radius: 200px;

  box-sizing: border-box;
  padding: 30px 40px;
  padding-top: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review--content p {
  font-style: italic;
  font-weight: 300;
  width: 80%;
}
.review--content h1 {
  font-size: 1.5rem;
  font-weight: 600;

  margin-top: 10px;
}

.review--content h4 {
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 992px) {
  .testimonials {
    height: 100%;
    padding-bottom: 2rem;
  }

  .testimonials--header h1 {
    font-size: 3rem;
    margin-top: 1.75rem;
  }

  .testimonials--body .quote {
    font-size: 3.5rem;
  }

  .review--content {
    border-radius: 50px;
  }
}

@media (max-width: 768px) {
  .testimonials--header h1 {
    font-size: 2.5rem;
  }
  .testimonials--body {
    width: 100%;
  }
  .testimonials--body button {
    display: none;
  }
  .testimonials--slider {
    width: 90%;
  }

  .review--content {
    border-radius: 50px;
  }
}

@media (max-width: 576px) {
  .review--img {
    margin: initial;
    align-self: flex-start;
  }
  .review--content {
    border-radius: 20px;
    min-height: 300px;
  }
  .review--content p {
    font-size: 0.9rem;
    width: 90%;
  }
  .review--content h1 {
    font-size: 1.3rem;
  }

  .review--content h4 {
    font-size: 0.9rem;
  }
}

@media (max-width: 360px) {
  .testimonials--header h1 {
    font-size: 2.2rem;
  }
  .testimonials--body .quote {
    font-size: 3rem;
  }
  .testimonials--slider {
    width: 100%;
  }
  .review--img {
    width: 80px;
    height: 80px;
  }
  .review--img img {
    width: 70px;
  }
  .review--content {
    min-height: 350px;
  }
}
