@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryFont: "Poppins", sans-serif;
  /* --primaryFont: 'Montserrat', sans-serif; */
  /* --primaryFont: 'Raleway', sans-serif; */
}

* {
  margin: 0;
  box-sizing: border-box;
  overflow: revert;
}
